// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-oldone-js": () => import("./../../../src/pages/404/oldone.js" /* webpackChunkName: "component---src-pages-404-oldone-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mymaite-js": () => import("./../../../src/pages/mymaite.js" /* webpackChunkName: "component---src-pages-mymaite-js" */),
  "component---src-pages-ovoid-js": () => import("./../../../src/pages/ovoid.js" /* webpackChunkName: "component---src-pages-ovoid-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-publish-js": () => import("./../../../src/pages/publish.js" /* webpackChunkName: "component---src-pages-publish-js" */),
  "component---src-pages-scrap-divers-js": () => import("./../../../src/pages/ScrapDivers.js" /* webpackChunkName: "component---src-pages-scrap-divers-js" */),
  "component---src-pages-stonks-9800-js": () => import("./../../../src/pages/stonks9800.js" /* webpackChunkName: "component---src-pages-stonks-9800-js" */),
  "component---src-pages-unichrome-js": () => import("./../../../src/pages/unichrome.js" /* webpackChunkName: "component---src-pages-unichrome-js" */)
}

